<template>
    <div>
        <h2 class="u-spacer--2">
            Simple Modal
        </h2>
        <p v-if="exampleModalProp">
            Custom Modal Prop: {{ exampleModalProp }}
        </p>
        <input type="text">
        <select>
            <option value="four">
                four
            </option>
            <option value="two">
                two
            </option>
            <option value="one">
                one
            </option>
        </select>
        <BaseButton @click="launchAdditionalModal">
            Pop another modal
        </BaseButton>
        <div class="o-row-full">
            <div class="o-extra-small--12 u-text--right">
                <BaseButton @click="popModal">
                    Close
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import { MODALS_MODULE_NAME, LAUNCH_MODAL, POP_MODAL } from '~coreModules/modals/js/modals-store';
import { MODALS } from '~modules/modals/js/modal-constants';

import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'ExampleModal',
    components: {
        BaseButton,
    },
    props: {
        exampleModalProp: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        modalOptions: MODALS.exampleModal,
    }),
    methods: {
        ...mapActions(MODALS_MODULE_NAME, {
            launchModal: LAUNCH_MODAL,
        }),
        ...mapMutations(MODALS_MODULE_NAME, {
            popModal: POP_MODAL,
        }),
        launchAdditionalModal() {
            this.launchModal(MODALS.exampleModal);
        },
    },
};
</script>

<style lang="scss">
</style>
